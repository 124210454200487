/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.links {
    color: #FFFFF5 !important;
    font-family: Nanum Gothic !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    width: 200px !important;
    text-align: center !important;
}

.linkRight {
    width: 100px !important;
}
