/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/

/* title */
.klaviyo_form_cs > * p > span {
    color: #0C0C0C !important;
    font-family: Graphik Wide Bold !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 16px !important;
}

.klaviyo_form_cs > * p > span > span {
    color: #0C0C0C !important;
    font-family: Graphik Wide Bold !important;
    font-size: 20px !important;
    font-style: italic !important;
    font-weight: 700 !important;
    line-height: 16px !important;
}

/* css input email */
.klaviyo_form_cs > * [data-testid="form-row"]:nth-child(2) > div > div {
    
}

.klaviyo_form_cs > * [data-testid="form-row"]:nth-child(2) > div > div > input {
    border: 1px solid black !important;
}

.klaviyo_form_cs > * [data-testid="form-row"]:nth-child(2) > div:nth-child(1) > div > input:hover {
    display: block !important;
    position: relative !important;
    border: 1px solid black !important;
}

.klaviyo_form_cs > * [data-testid="form-row"]:nth-child(2) > div:nth-child(2) {
    height: 100% !important;
    z-index: 9 !important;
    flex-flow: column !important;
    text-align: right !important;
    justify-content: center !important;
    position: absolute !important;
    right: 0;
    /* width: 100% !important; */
}

/* .klaviyo_form_cs > * [data-testid="form-row"]:nth-child(2) > div:nth-child(2) > button {
    border: 1px solid black !important;
} */

/* .klaviyo_form_cs > * input[id^='email_']:focus {
    border: 1px solid black !important;
}

.klaviyo_form_cs > * input[id^='email_']:hover {
    border: 1px solid black !important;
}

.klaviyo_form_cs > * [data-testid="form-row"] {
    border: 1px solid black !important;
} */
