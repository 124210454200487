/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.announcements_div {
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #B9FAA7;
    padding-top: 8px;
    padding-bottom: 8px;
    display: block;
    width: 100%;
}

.announcements_link {
    text-align: center;
    color: #141414;
    font-family: Nanum Gothic Bold !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1%;
}

.announcements_link_separator {
    color: #141414;
    font-family: Nanum Gothic Bold !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.42px;
}

.closeButton {
    color: #141414;
    font-family: Nanum Gothic Bold !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    right: 1%;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
}

.closeButton:after {
    content: 'CLOSE [X]';
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .closeButton:after {
        content: '[X]';
    }

    .announcements_link {
        font-size: auto;
        padding: 20px;
    }
}