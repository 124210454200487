.footerColumnTitle {
    color: #141414 !important;
    font-family: Nanum Gothic Bold !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 16px !important; /* 106.667% */
    letter-spacing: 0 !important;
    margin-bottom: 15px !important;
}

.footerColumnLink {
    color: #666 !important;
    font-family: Nanum Gothic !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important; /* 133.333% */
    margin-bottom: 10px !important;
}

.mainFooterLink {
    color: #141414 !important;
    font-family: Nanum Gothic !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important; /* 133.333% */
    white-space: nowrap !important;
}

.footerInnerMenu {
    color: #141414 !important;
    font-family: Nanum Gothic !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important; /* 133.333% */
}

.tar {
    text-align: right;
}