/*

Add custom font-face declarations in this file. Font files should be included in this directory.

Example:

@font-face {
  font-family: 'Roboto, system-ui, sans-serif';
  src: url('roboto.woff2') format('woff2'), url('roboto.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-weight: 400;
}

*/
@font-face {
  font-family: 'Nanum Gothic';
  src: url('NanumGothic/NanumGothic-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nanum Gothic Bold';
  src: url('NanumGothic/NanumGothic-ExtraBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Wide';
  src: url('GraphikWide/GraphikWide-Light.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Wide Bold';
  src: url('GraphikWide/GraphikWide-Bold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Mint Grotesk Display';
  src: url('MintGroteskDisplay/MintGroteskDisplay-Light.otf');
  font-style: normal;
}

@font-face {
  font-family: 'Mint Grotesk Display Bold';
  src: url('MintGroteskDisplay/MintGroteskDisplay-Bold.otf');
  font-style: normal;
}
